import React from "react"
import {routeConstants} from "../../constants/RouteConstants";
import {StateFilterOption, TaskStatus} from "../../widgets/TaskStatus";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import {ActionButtons} from "../../widgets/ActionButtons";
import FilteredTable from "../../components/containers/FilteredTable";
import {apiConstants} from "../../constants/ApiConstants";

export function MosregTasks() {

  let filterView = (state, activeState, handler) => {
    return (<StateFilterOption state={state} active={activeState === state.State} onClick={handler} />)
  }
  const columns = [
    {
      dataField: "SettingsID",
      text: "Аккаунт",
      headerClasses: "text-nowrap",
    },
    {
      dataField: "EndTime",
      text: "Окончание",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return dateFormat(row.EndTime, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "Name",
      text: "Название",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return (<a href={row.Link} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "StartPrice",
      text: "Старт.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "ThirdPrice",
      text: "Подача",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return (row.ThirdPrice > 0 ? row.ThirdPrice : row.StartPrice);
      },
    },
    {
      dataField: "SecondPrice",
      text: "Переторжка",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "FirstPrice",
      text: "Закупка",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "LastPrice",
      text: "Посл.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "User",
      text: "Создал",
      sort: false
    },
    {
      dataField: "Status",
      text: "Статус",
      sort: false
    },
    {
      dataField: "State",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return (<TaskStatus status={row.State}/>);
      },
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        return (<ActionButtons prefix='mo' status={row.State} id={row.ID} />)
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    <>
      <FilteredTable
        sortField={"EndTime"}
        dataUrl={apiConstants.API_URL + '/motasks'}
        filterView={filterView}
        columns={columns}
        title={"Задачи на Mosreg.ru"}
        addLink={routeConstants.MOSREG_TASKS_ADD}
      />
    </>
  );
}
