/* eslint-disable */
import React, {useEffect, useState} from "react";
import {ContentLayout} from "../../layouts/ContentLayout";
import axios from "axios";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "../../components/pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import {apiConstants} from "../../constants/ApiConstants";
import QueryForm from "./QueryForm";
import {useParams} from "react-router";
import TenderDetails from "./TenderDetails";
import TenderItemList from "./TenderItemList";

export default function SearchWidget() {

  let {id} = useParams();

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': 'bearer ' + token
    }
  };

  const queryTemplate = {
    id: null,
    name: "",
    keywords: [],
    stopwords: [],
    regions: [],
    platforms: [],
    active: true,
    inDoc: false,
    from: 0,
    size: 20,
  };
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [queries, setQueries] = useState([]);
  const [query, setQuery] = useState(queryTemplate);
  const [editMode, setEditMode] = useState(false);
  const [tenders, setTenders] = useState([]);
  const [total, setTotal] = useState(0);

  const [showFavorites, setShowFavorites] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  /** Подгружаем данные при первой отрисовке */
  useEffect(() => {

    loadOptions().then(({regions, platforms}) => {
      let data = regions.data;

      if (data.length > 0) {
        let grouped = {};

        data.forEach((value) => {
          if (value.federal_district && value.federal_district.length > 0) {
            value.federal_district += ' федеральный округ'


            if (!grouped[value.federal_district]) {
              grouped[value.federal_district] = [];
            }

            grouped[value.federal_district].push({label: value.name, value: value.kladr});
          }
        });

        let options = [];

        Object.keys(grouped).forEach(value => {
          options.push({
            label: value,
            options: grouped[value]
          });
        })

        setRegions(options);
      }

      data = platforms.data;

      if (data.length > 0) {
        let options = [];

        data.forEach(value => {
          options.push({
            label: value.name,
            value: value.code
          });
        });

        setPlatforms(options);
      }
    });
    loadQueries();
  }, []);

  /** Обновление результатов поиска при изменении запроса */
  useEffect(() => {
    if (init) {
      return
    }

    loadSearchResult(query, (tenders, total) => {
      setTenders(tenders);
      setTotal(total);
    });
  }, [query.from, query.id, init, showHidden, showFavorites])

  const loadOptions = async () => {
    let regions = await axios.get(`${apiConstants.SEARCH_URL}/regions?size=10000`);
    let platforms = await axios.get(`${apiConstants.SEARCH_URL}/platforms?size=10000`);

    return {regions, platforms}
  }

  const loadQueries = (id = null) => {

    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': 'bearer ' + token
      }
    };

    axios.get(apiConstants.API_URL + '/queries', config).then((resp) => {
      let {items} = resp.data;
      if (items && items.length > 0) {
        setQueries(items);
        if (id === null) {
          setQuery(items[0]);
        } else {
          const query = items.find(item => item.id === id)
          setQuery(query);
          loadSearchResult(query, (tenders, total) => {
            setTenders(tenders);
            setTotal(total);
          });
        }
      } else {
        setQuery(queryTemplate);
        setQueries([]);
        setEditMode(true);
      }

      setInit(false);
    });
  }

  const loadSearchResult = (query, callback, showLoading = true) => {
    if (showLoading) {
      setLoading(true);
      setTenders([]);
    }

    if (showFavorites) {
      axios.get(`${apiConstants.API_URL}/search?type=favorites&from=${query.from}&size=${query.size}`, config).then((resp) => {
        processResponse(resp.data, callback);
      })
    } else if (showHidden) {
      axios.get(`${apiConstants.API_URL}/search?type=hidden&from=${query.from}&size=${query.size}`, config).then((resp) => {
        processResponse(resp.data, callback);
      })
    }
    else {
      let request = {
        from: query.from,
        size: query.size,
        query: {
          active: query.active,
          search_in_docs: query.inDoc
        }
      }

      if (query.keywords && query.keywords.length > 0) {
        request.query = {...request.query, keywords: query.keywords}
      }

      if (query.stopwords && query.stopwords.length > 0) {
        request.query = {...request.query, stopwords: query.stopwords}
      }

      if (query.regions && query.regions.length > 0) {
        let regions = query.regions.filter(region => region.value !== "").map(region => region.value)
        request.query = {...request.query, region: regions}
      }

      if (query.platforms && query.platforms.length > 0) {
        let platforms = query.platforms.filter(platform => platform.value !== "").map(region => region.value)
        request.query = {...request.query, platform: platforms}
      }

      axios.post(`${apiConstants.API_URL}/search`, request, config).then((resp) => {
        processResponse(resp.data, callback);
      })
    }
  }

  const processResponse = ({total, hits}, callback) => {
    if (hits && hits.length > 0) {
      let tenders = [];
      hits.forEach((hit) => {
        tenders.push({...hit._source, in_favorites: hit.in_favorites, in_use: hit.in_use, highlight: hit.highlight});
      })

      callback(tenders, total.value);
    } else {
      callback([], 0);
    }

    setLoading(false);
  }

  const onSearchHandler = (query) => {
    loadSearchResult(query, (tenders, total) => {
      setTenders(tenders);
      setTotal(total);
    });
  }

  const onSaveHandler = (id = null) => {
    setEditMode(false);
    loadQueries(id);
  }

  const onDeleteHandler = (id) => {

    if (window.confirm("Вы уверены что хотите удалить фильтр?")) {
      axios.delete(apiConstants.API_URL + `/queries/${id}`, config).then(() => {
        loadQueries();
      })
    }
  }

  const onChangeTableHandler = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    setQuery({
      ...query,
      from: (pageNumber - 1) * sizePerPage,
      size: sizePerPage
    });
  };

  const onHideTender = (id) => {
    axios.post(apiConstants.API_URL + `/hide`, {tender_id: id}, config).then((response) => {
      loadSearchResult(query, (tenders, total) => {
        setTenders(tenders);
        setTotal(total);
      }, false);
    });
  }

  const onAddToFavorite = (id, add = true) => {
    if (add) {
      const newTenders = tenders.map(tender => {
        if (tender.tender_id === id) {
          return {...tender, in_favorites: true}
        }

        return tender;
      });

      setTenders(newTenders);

      axios.post(apiConstants.API_URL + `/favorites`, {tender_id: id}, config).then(() => {
      });
    } else {

      const newTenders = tenders.map(tender => {
        if (tender.tender_id === id) {
          return {...tender, in_favorites: false}
        }

        return tender;
      });

      setTenders(newTenders);
      axios.delete(apiConstants.API_URL + `/favorites/${id}`).then(() => {
      });
    }
  }

  const onClickAddNew = () => {
    setShowHidden(false);
    setShowFavorites(false);
    setQuery(queryTemplate);
    setEditMode(true);
  }

  const onClickQueryTab = (q) => {
    setQuery(q);
    setEditMode(false);
    setShowFavorites(false);
    setShowHidden(false);
  }

  const onClickFavoritesTab = () => {
    setLoading(true);
    setEditMode(false);
    setShowHidden(false);
    setShowFavorites(true);
    setQuery(queryTemplate);
  }

  const onClickHiddenTab = () => {
    setLoading(true);
    setEditMode(false);
    setShowHidden(true);
    setShowFavorites(false);
    setQuery(queryTemplate);
  }

  const onRemoveFromFavorites = (id) => {
    axios.delete(apiConstants.API_URL + `/favorites/${id}`, config).then(() => {
      loadSearchResult(query, (tenders, total) => {
        setTenders(tenders);
        setTotal(total);
      }, false);
    });
  }

  const onRemoveFromHidden = (id) => {
    axios.delete(apiConstants.API_URL + `/hide/${id}`, config).then(() => {
      loadSearchResult(query, (tenders, total) => {
        setTenders(tenders);
        setTotal(total);
      }, false);
    });
  }

  const columns = [
    {
      dataField: "tender_id",
      text: "Аккаунт",
      headerAttrs: {
        hidden: true
      },
      formatter: function (cell, row) {
        return (
          <TenderItemList
            row={row}
            onFavoriteClick={showFavorites || showHidden ? null : onAddToFavorite}
            onHideClick={showFavorites || showHidden ? null : onHideTender}
            onDelete={showFavorites ? onRemoveFromFavorites : showHidden ? onRemoveFromHidden : null}
          />
        );
      },
    }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPage: query.size,
    sizePerPageList: [
      { text: "20", value: 20 }
    ]
  };

  return (
    <>
      <ContentLayout title="Поиск тендеров">
        <div className="card">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              {
                queries.length > 0 &&
                  queries.map(q => {
                    return (
                      <li className="nav-item position-relative" key={q.id}>
                        <span
                          style={{cursor: "pointer"}}
                          className={"nav-link pr-6" + (q.id === query.id ? ' active' : '')}
                          onClick={() => {onClickQueryTab(q)}}
                        >
                          {q.name}
                          {
                            q.id !== query.id &&
                            <>
                              <i className="fa fa-pencil text-secondary position-absolute" aria-hidden="true" style={{top: "13px", right: "35px"}} />
                              <i className="fa fa-trash text-secondary position-absolute" aria-hidden="true" style={{top: "13px", right: "12px"}} />
                            </>
                          }
                        </span>
                        {
                          q.id === query.id &&
                            <>
                              <i className="fa fa-pencil text-primary position-absolute" style={{top: "13px", right: "35px"}} aria-hidden="true" onClick={() => setEditMode(true)}/>
                              <i className="fa fa-trash text-danger position-absolute" style={{top: "13px", right: "12px"}} aria-hidden="true" onClick={() => onDeleteHandler(q.id)}/>
                            </>
                        }
                      </li>
                    )
                  })
              }
              <li className="nav-item">
                <span
                  style={{cursor: "pointer"}}
                  className={"nav-link"  + (showFavorites ? ' active' : '')}
                  onClick={onClickFavoritesTab}
                >
                  <i className="fa fa-star" aria-hidden="true"/>
                </span>
              </li>
              <li className="nav-item">
                <span
                  style={{cursor: "pointer"}}
                  className={"nav-link"  + (showHidden ? ' active' : '')}
                  onClick={onClickHiddenTab}
                >
                  <i className="fa fa-eye-slash" aria-hidden="true"/>
                </span>
              </li>
              <li className="nav-item" onClick={onClickAddNew}>
                <span style={{cursor: "pointer"}} className={"nav-link" + (query.id === null && !showFavorites && !showHidden ? ' active' : '')}><i className="fa fa-plus" aria-hidden="true"/></span>
              </li>
            </ul>
          </div>
          <div className="card-body p-0 p-sm-2">
            {
              editMode &&
                <QueryForm
                  query={query}
                  regions={regions}
                  platforms={platforms}
                  onSearchHandler={onSearchHandler}
                  onSaveHandler={onSaveHandler}
                  onCancelHandler={() => {
                    setEditMode(false);
                    loadSearchResult(query, (tenders, total) => {
                      setTenders(tenders);
                      setTotal(total);
                    });
                  }}
                />
            }
            <div className="tender-list pt-3">
              {
                loading &&
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
              <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({paginationProps, paginationTableProps}) => {
                  return (
                    <Pagination
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-sm table-light table-responsive-lg"
                        bootstrap4
                        data={tenders}
                        columns={columns}
                        keyField="tender_id"
                        remote
                        onTableChange={onChangeTableHandler}
                        defaultSorted={[{dataField: "", order: "desc"}]}
                        {...paginationTableProps}
                      >
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </ContentLayout>
      {
        id && <TenderDetails id={id} />
      }
    </>
  );
}

export const declOfNum = (number, words) => {
  return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}

export const tenderType = (type) => {
  switch (type) {
    case "action":
      return "Аукцион";
    case "price_request":
      return "Запрос котировок";
    case "contest":
      return "Конкурс";
    case "small_order":
      return "Малая закупка";
    case "immediate_request":
      return "Оперативная закупка";
    case "low_free":
      return "Не регламентируется";
    case "other":
      return "Прочее";
  }
}

export const tenderStatus = (status) => {
  switch (status) {
    case "planning":
      return <span className="badge badge-info ml-2">Планируется</span>;
    case "accepting":
      return <span className="badge badge-primary ml-2">Прием заявок</span>;
    case "approving":
      return <span className="badge badge-warning ml-2">Согласование</span>;
    case "playing":
      return <span className="badge badge-primary ml-2">Идут торги</span>;
    case "consideration":
      return <span className="badge badge-warning ml-2">Рассмотрение предложений</span>;
    case "completed":
      return <span className="badge badge-info ml-2">Завершен</span>;
    case "canceled":
      return <span className="badge badge-secondary ml-2">Отменен</span>;
    case "uncompleted":
      return <span className="badge badge-secondary ml-2">Не состоялся</span>;
    case "unknown":
      return <span className="badge badge-secondary ml-2">Не определено</span>;
  }
}