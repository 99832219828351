import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {taskActions} from "../../actions/TaskActions";
import {taskServices} from "../../services/TaskServices";
import {EatTaskFormView} from "../views/EatTaskFormView";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";

class EatTaskFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!props.id,
            settings_id: '',
            name: '',
            url: '',
            buyPrice: 0,
            stopPrice: 0,
            startBefore: 13
        };

        this.prefix = 'eat';
        this.id = props.id;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': 'bearer ' + token
            }
        };

        if (this.id) {
            const {dispatch} = this.props;
            dispatch(formActions.reset());

            Axios.get(apiConstants.API_URL + "/eat/accounts", config).then((resp) => {
                const accounts = resp.data.data;

                taskServices.setPrefix(this.prefix).get(this.id, (data) => {
                    this.setState({
                        isLoading: false,
                        settings_id: data.SettingsID,
                        name: data.Name,
                        url: data.Link,
                        buyPrice: data.FirstPrice,
                        stopPrice: data.SecondPrice,
                        startBefore: data.Time ? data.Time : '13',
                        accounts: accounts,
                    })
            })

            });
        } else {
            Axios.get(apiConstants.API_URL + "/eat/accounts", config).then((resp) => {
                const {data} = resp.data;

                this.setState({
                    settings_id: Object.keys(data).length > 0 ? Object.keys(data)[0] : '',
                    accounts: data,
                });
            })
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        const params = new URLSearchParams();
        params.append('id', this.id);
        params.append('name', this.state.name);
        params.append('url', this.state.url);
        params.append('buyPrice', this.state.buyPrice);
        params.append('stopPrice', this.state.stopPrice);
        params.append('startBefore', this.state.startBefore);
        params.append('settings_id', this.state.settings_id);

        if (this.id) {
            dispatch(taskActions.updateTask(this.id, params, this.prefix));
        } else {
            dispatch(taskActions.addTask(params, this.prefix));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
              <Loading />
            );
        } else {
            console.log(this.state);
            return <EatTaskFormView data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(EatTaskFormContainer);