import React, {useState, useEffect} from "react"
import {apiConstants} from "../../constants/ApiConstants";
import dateFormat from "dateformat";
import {StateFilterOption, TaskStatus} from "../../widgets/TaskStatus";
import {ActionButtons} from "../../widgets/ActionButtons";
import {routeConstants} from "../../constants/RouteConstants";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import FilteredTable from "../../components/containers/FilteredTable";
import {Modal, Table} from "react-bootstrap";

export function MosTasks() {

  const [show, setShow] = useState(false);
  const [competitors, setCompetitors] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let filterView = (state, activeState, handler) => {
    return (<StateFilterOption state={state} active={activeState === state.State} onClick={handler} />)
  }
  const columns = [
    {
      dataField: "SettingsID",
      text: "Аккаунт",
      headerClasses: "text-nowrap",
    },
    {
      dataField: "EndTime",
      text: "Окончание",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return dateFormat(row.EndTime, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "Name",
      text: "Название",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return (<a href={row.Link} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "StartPrice",
      text: "Старт.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "SecondPrice",
      text: "Стоп.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "FirstPrice",
      text: "Закупка",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "LastPrice",
      text: "Посл.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Competitors",
      text: "Конкуренты",
      sort: false,
      formatter: function (cell, row) {
        if (row.Competitors && row.Competitors.length > 0) {
          return (
            <span
              className={"competitors-link"}
              onClick={() => {
                setCompetitors(row.Competitors);
                handleShow();
              }}
            >
              {row.Competitors && row.Competitors.length}
            </span>
          );
        }
      },
    },
    {
      dataField: "Time",
      text: "Время",
      sort: false
    },
    {
      dataField: "User",
      text: "Создал",
      sort: false
    },
    {
      dataField: "Status",
      text: "Статус",
      sort: false
    },
    {
      dataField: "State",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return (<TaskStatus status={row.State}/>);
      },
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        return (<ActionButtons status={row.State} id={row.ID} />)
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    <>
      <FilteredTable
        sortField={"EndTime"}
        dataUrl={apiConstants.API_URL + '/tasks'}
        filterView={filterView}
        columns={columns}
        title={"Задачи на Mos.ru"}
        addLink={routeConstants.MOS_TASK_ADD}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Конкуренты</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table table-sm table-light table-striped table-condensed table-responsive-lg">
            <thead>
              <tr>
                <th>id</th>
                <th>Название</th>
                <th>Ставка</th>
                <th>Победил</th>
              </tr>
            </thead>
            <tbody>
            {
              competitors && competitors.map((c) => {
                return (
                  <tr key={c.UserId}>
                    <td>{c.UserId}</td>
                    <td>{c.Name}</td>
                    <td>{c.Bet}</td>
                    <td>{c.Won ? 'Да' : 'Нет'}</td>
                  </tr>
                );
              })
            }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}