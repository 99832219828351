import React from 'react'
import {Menu} from "./Menu";
import {connect} from "react-redux";
import {Loading} from "../widgets/Loading";

function MainLayout(props) {

    let content;

    if (props.user.login !== undefined) {
        content =
            <React.Fragment>
                <Menu /> {props.children}
            </React.Fragment>
    } else {
        content = <Loading/>
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <main className="col-12">
                    {content}
                </main>
            </div>
        </div>
    );
}

function mapStateToProps(store) {
    return {
        user: store.userReducer
    };
}


export default connect(mapStateToProps)(MainLayout);