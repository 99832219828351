import React from "react"

export function MosregTaskFormView(props) {
  return (
    <form name="form" onSubmit={props.onSubmit}>
      {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
      <div className="form-group col-12">
        <label htmlFor="settings_id">Аккаунт</label>
        <select value={props.data.settings_id} name="settings_id" className="form-control" onChange={props.onChange}>
          {
            props.data.accounts && Object.keys(props.data.accounts).length > 0 ?
              Object.keys(props.data.accounts).map((key) => {
                const account = props.data.accounts[key];
                return <option value={key}>{account}</option>
              }) :
              <option key="empty" value="">- Добавьте аккаунт Mosreg.ru -</option>
          }
        </select>
        <small id="nameHelp" className="form-text text-muted">Выберите аккаунт на zakupki.mos.ru</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="name">Название закупки</label>
        <input name="name" type="text" className="form-control" id="name" placeholder="Название"
               onChange={props.onChange} value={props.data.name}/>
        <small id="nameHelp" className="form-text text-muted">Не объязательное, если не указать будет подгружено с market.mosreg.ru</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="link">Ссылка</label>
        <input name="url" type="text" className="form-control" required id="link" placeholder="Ссылка"
               onChange={props.onChange} value={props.data.url}/>
        <small id="linkHelp" className="form-text text-muted">Ссылка на страницу котировочной сессии на портале
          market.mosreg.ru</small>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="form-group col-4">
            <label htmlFor="firstprice">Цена закупки</label>
            <input name="buyPrice" type="text" required className="form-control" id="firstprice" placeholder="Цена"
                   onChange={props.onChange} value={props.data.buyPrice}/>
            <small id="firstpriceHelp" className="form-text text-muted">Укажите цену закупки, это для справки и роботом данное поле не используется</small>
          </div>
          <div className="form-group col-4">
            <label htmlFor="startprice">Цена подачи предложения</label>
            <input name="startPrice" type="text" readOnly="readonly" className="form-control" id="startprice" placeholder="Цена"
                   onChange={props.onChange} value={props.data.startPrice} />
            <small id="startpriceHelp" className="form-text text-muted">
              <span style={{ color: 'red', fontWeight: 'bold' }}>Внимание не меняйте НМЦК после подачи предложения, робот будет снижаться от изменённой суммы и уйдет ниже стоп цены! Над исправление идет работа и займет какое-то время</span>
            </small>
          </div>
          <div className="form-group col-4">
            <label htmlFor="secondprice">Цена для переторжки</label>
            <input name="stopPrice" type="text" required className="form-control" id="secondprice" placeholder="Цена"
                   onChange={props.onChange} value={props.data.stopPrice}/>
            <small id="secondpriceHelp" className="form-text text-muted">
              Укажите минимальную цену которую робот подаст на переторжке
            </small>
          </div>
        </div>
      </div>
      <div className="col-12">
        <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
          {props.result.type === 'loading' &&
          <span className="spinner-border spinner-border-sm" role="status"></span>} Сохранить
        </button>
      </div>
    </form>
  );
}