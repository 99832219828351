import React, {useEffect, useState} from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import Select from "react-select";
import axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";

export default function QueryForm(props) {

  const [query, setQuery] = useState({
    name: props.query.name,
    keywords: props.query.keywords,
    stopwords: props.query.stopwords,
    regions: props.query.regions && props.query.regions.length > 0 ? props.query.regions : [{label: "Все регионы", value: ""}],
    platforms: props.query.platforms && props.query.platforms.length > 0 ? props.query.platforms : [{label: "Все площадки", value: ""}],
    active: props.query.active,
    inDoc: props.query.inDoc,
  });

  const onSaveQueryHandler = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': 'bearer ' + token
      }
    };

    let regions = query.regions.filter(r => r.value !== "");
    let data = {...query, regions: regions}

    if (props.query.id) {
      axios.put(apiConstants.API_URL + `/queries/${props.query.id}`, data, config).then(resp => {
        props.onSaveHandler(props.query.id);
      })
    } else {
      axios.post(apiConstants.API_URL + `/queries`, data, config).then(resp => {
        props.onSaveHandler();
      })
    }
  }

  const Keys = {
    TAB: 9,
    SPACE: 32,
    COMMA: 188,
  };

  useEffect(() => {}, [query]);

  useEffect(() => {
    setQuery({
      name: props.query.name,
      keywords: props.query.keywords,
      stopwords: props.query.stopwords,
      regions: props.query.regions && props.query.regions.length > 0 ? props.query.regions : [{label: "Все регионы", value: ""}],
      platforms: props.query.platforms && props.query.platforms.length > 0 ? props.query.platforms : [{label: "Все площадки", value: ""}],
      active: props.query.active,
      inDoc: props.query.inDoc,
    });
  }, [props.query]);

  const onRegionSelected = values => {
    if (values.length > 0) {
      const regions = values.filter(r => r.value !== "")
      setQuery({...query, regions: regions})
    } else {
      setQuery({...query, regions: [{label: "Все регионы", value: ""}]})
    }
  }

  const onPlatformSelected = values => {
    if (values.length > 0) {
      const platforms = values.filter(r => r.value !== "")
      setQuery({...query, platforms: platforms})
    } else {
      setQuery({...query, platforms: [{label: "Все площадки", value: ""}]})
    }
  }

  return (
    <>
      <div className="form-group col-6">
        <label htmlFor="exampleInputEmail1">Название фильтра</label>
        <input type="text" className="form-control" value={query.name} onChange={e => setQuery({...query, name: e.target.value})}/>
        <small className="form-text text-muted">Минимальная длинна ключевого слова - 3 символа, поиск идет по части слова</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="exampleInputEmail1">Ключевые слова</label>
        <ReactTagInput
          tags={query.keywords}
          placeholder="Введите ключевое слово и нажмите Enter"
          maxTags={40}
          delimiters={[Keys.TAB, Keys.SPACE, Keys.COMMA]}
          onChange={(newTags) => setQuery({...query, keywords: newTags})}
        />
        <small className="form-text text-muted">Минимальная длинна ключевого слова - 3 символа, поиск идет по части слова</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="exampleInputEmail1">Стоп слова</label>
        <ReactTagInput
          tags={query.stopwords}
          placeholder="Введите стоп слово и нажмите Enter"
          maxTags={40}
          onChange={(newTags) => setQuery({...query, stopwords: newTags})}
        />
        <small className="form-text text-muted">Минимальная длинна стоп слова - 3 символа, поиск идет по части слова</small>
      </div>
      <div className="form-group col-12">
        <div className="form-check col-6">
          <input
            name="searchInDoc"
            type="checkbox"
            className="form-check-input"
            id="searchInDoc"
            onChange={() => setQuery({...query, inDoc: !query.inDoc})}
            checked={query.inDoc}
          />
          <label className="form-check-label" htmlFor="searchInDoc">Искать в документах</label>
        </div>
        <div className="form-check col-6">
          <input
            name="onlyActive"
            type="checkbox"
            className="form-check-input"
            id="onlyActive"
            onChange={() => setQuery({...query, active: !query.active})}
            checked={query.active}
          />
          <label className="form-check-label" htmlFor="onlyActive">Только активные</label>
        </div>
      </div>
      <div className="form-group col-12">
        <label htmlFor="exampleInputEmail1">Регионы</label>
        <Select
          options={props.regions}
          isMulti={true}
          isSearchable={true}
          placeholder={"Выберите регионы, по умолчанию показываются все"}
          onChange={onRegionSelected}
          closeMenuOnSelect={false}
          value={query.regions}
        />
        <small className="form-text text-muted">Выберите регионы для поиска</small>
      </div>
      <div className="form-group col-12">
        <label htmlFor="exampleInputEmail1">Площадки</label>
        <Select
          options={props.platforms}
          isMulti={true}
          isSearchable={true}
          placeholder={"Выберите площадки, по умолчанию показываются все"}
          onChange={onPlatformSelected}
          closeMenuOnSelect={false}
          value={query.platforms}
        />
        <small className="form-text text-muted">Выберите регионы для поиска</small>
      </div>
      <div className="col-12">
        <button className="btn btn-primary" onClick={() => onSaveQueryHandler()}>Сохранить</button>
        <button className="btn btn-secondary ml-2" onClick={props.onCancelHandler}>Отмена</button>
        <button className="btn btn-info ml-2" onClick={() => props.onSearchHandler(query)}>Предпросмотр</button>
      </div>
    </>
  )
}