/* eslint-disable */
import React, {useState, useEffect} from "react"
import Axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider} from "react-bootstrap-table2-paginator";
import {Pagination} from "../pagination/Pagination";
import filterFactory from "react-bootstrap-table2-filter";
import {Link} from "react-router-dom";

export default function FilteredTable(props) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState({
    page: 1,
    size: 15,
    sort_field: props.sortField,
    sort_order: "desc"
  });
  const [states, setStates] = useState([]);
  const [stateFilter, setStateFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [tableRef, setTableRef] = useState(null);

  const loadData = (params) => {
    setLoading(true);

    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Authorization': 'bearer ' + token
      }
    };

    Axios.get(props.dataUrl + '?' + params, config).then((response) => {
      const result = response.data;
      let items = [];
      if (result.data && result.data.Items) {
        items = result.data.Items;
      }
      setTotal(result.data.Pagination.Total);
      setData(items);

      if (result.data.State) {
        let states = result.data.State;

        states.sort((a,b) => {
          if (a.State < b.State) {
            return 1;
          }
          if (a.State > b.State) {
            return -1;
          }

          return 0;
        });

        setStates(states);
      }
      setLoading(false);
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    const params = new URLSearchParams(query);
    params.append("status_filter", stateFilter);
    params.append("name_filter", nameFilter);

    loadData(params, query);

    const t = setInterval(() => {
      loadData(params, query);
    }, 1500);

    return () => {
      clearInterval(t);
    }
  }, [stateFilter, query, nameFilter]);

  const onChangeTableHandler = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    const pageNumber = page || 1;
    setQuery({
      page: pageNumber,
      size: sizePerPage,
      sort_field: sortField,
      sort_order: sortOrder
    });
  };

  const onChangeStateFilter = (state) => {
    if (tableRef.handleRemotePageChange) {
      tableRef.handleRemotePageChange(1, 15);
    }
    setStateFilter(state);
    setNameFilter('');
  }

  const onChangeNameFilter = (state) => {
    if (tableRef.handleRemotePageChange) {
      tableRef.handleRemotePageChange(1, 15);
    }
    setNameFilter(state);
    setStateFilter('');
  }

  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPage: query.size,
    sizePerPageList: [
      { text: "15", value: 15 },
      { text: "30", value: 30 },
      { text: "100", value: 100 }
    ]
  };

  return (
    <div className="col-12">
      <div className="mb-3 mt-3">
        <h3>{props.title}</h3>
      </div>
      <div className="table-responsive-lg">
        {
          props.addLink ?
            <div className={"row mb-2"}>
              <div className={"col-12"}>
                <Link to={props.addLink} className="btn btn-primary">
                  <i className="fa fa-plus" />
                  <span style={{display: "inline-block", paddingLeft: '5px'}}>Добавить</span>
                </Link>
              </div>
            </div> : ''
        }
        <div className="form-group row">
          <div className="col-md-2 col-12 mb-3 mb-md-0">
            <input
              type="text"
              className="form-control"
              name="search"
              placeholder="Название"
              value={nameFilter}
              onChange={(e) => {onChangeNameFilter(e.target.value)}}
            />
            <small className="form-text text-muted">
              <b>Поиск</b> по названию
            </small>
          </div>
          <div className="col-md-10 col-12 mb-3 mb-md-0">
            <div className={"btn-group" + (window.innerWidth < 768 ? '-vertical' : '') + " btn-group-toggle"} role="group" aria-label="Basic example">
              <button type="button" className={"btn btn-light" + (stateFilter === '' ? ' active' : '')} onClick={() => onChangeStateFilter('')}>Все</button>
              {
                states.map((state) => {
                  return props.filterView(state, stateFilter, onChangeStateFilter);
                })
              }
            </div>
          </div>
        </div>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={loading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-sm table-light table-striped table-condensed table-responsive-lg"
                  bootstrap4
                  data={data}
                  columns={props.columns}
                  keyField="ID"
                  remote
                  ref={ref => setTableRef(ref)}
                  onTableChange={onChangeTableHandler}
                  defaultSorted={[{ dataField: "EndTime", order: "desc" }]}
                  filter={filterFactory()}
                  {...paginationTableProps}
                >
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </div>
    </div>
  );
}