import React from "react";
import {routeConstants} from "../../constants/RouteConstants";

export function WidgetMosOffers(props) {
    let dateFormat = require('dateformat');

    return (
        <div className="card">
            <div className="card-header">
                Оферты Mos.ru
            </div>
            <div className="card-body">
                <table className="table table-sm table-xs table-light table-striped table-condensed">
                    <thead>
                    <tr>
                        <th className="text-nowrap">Подписать до</th>
                        <th>Оферта</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            props.items !== null && props.items.length > 0 ?
                                props.items.map((item) => (
                                    <tr key={item.ID}>
                                        <td style={{width: "100px"}}>{dateFormat(new Date(item.EndTime), "HH:MM dd.mm.yy")}</td>
                                        <td>
                                            <a href={item.Url} target="_blank" rel="noopener noreferrer">{item.Name.replace(/^(.{40}[^\s]*).*/, "$1")}</a>
                                        </td>
                                        <td style={{width: "76px"}}>
                                            {item.Status === 1 ? <span className="state-4 badge badge-success">Подписана</span> : <span className="state-4 badge badge-warning">Не подписана</span>}
                                        </td>
                                    </tr>
                                )) :
                                <tr>
                                    <td colSpan="2">У вас нет еще ни одной оферты</td>
                                </tr>
                        }
                    </tbody>
                </table>
                <a href={routeConstants.MOS_OFFERS} className="card-link">Все оферты</a>
            </div>
        </div>
    );
}